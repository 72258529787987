<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <router-link :to="'/analyses/board'">
          Analyses >
        </router-link>
        <span>Analyses des ressources</span>
      </div>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Analyses des ressources
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row">
      <div class="div_left"
           v-if="(user[1] == 'FER' && user[0] == 'DAF')||(user[1] == 'FER' && user[0] == 'ADMIN')">
        <button class="btn btn-blue pl-5 pr-5 op-btn"
                data-toggle="modal"
                ref="modal_button"
                data-target="#exampleModal">Importer données statistiques</button>
      </div>
    </div>
    <div class="row mb-4">
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Période du: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_debut"/>
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_fin"/>
        </div>
        <div class="form-group">
          <label for="">Consultation: </label>
          <select class="form-control mx-2" 
                  name="" 
                  id=""
                  v-model="consultation">
            <option value="MENSUELLE">Mensuelle</option>
            <option value="ANNUELLE">Annuelle</option>
          </select>
        </div>
        <div class="form-group">
          <button type="button"
                  class="btn btn-info"
                  @click="changeYear()">
            Rechercher
          </button>
        </div>
      </form>
    </div>
    <ul class="nav nav-tabs"
        id="myTab"
        role="tablist">
      <li class="nav-item">
        <a class="nav-link active"
           id="levee-tab"
           data-toggle="tab"
           href="#levee"
           role="tab"
           aria-controls="home"
           aria-selected="true"
           @click="selection('levee')">Emprunts & Facilités de trésorerie</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           id="raff-tab"
           data-toggle="tab"
           href="#raff"
           role="tab"
           aria-controls="profile"
           aria-selected="false"
           @click="selection('ra')">Ressources affectées</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           id="recp-tab"
           data-toggle="tab"
           href="#recp"
           role="tab"
           aria-controls="profile"
           aria-selected="false"
           @click="selection('rp')">Recettes de péage</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           id="redp-tab"
           data-toggle="tab"
           href="#redp"
           role="tab"
           aria-controls="profile"
           aria-selected="false"
           @click="selection('rps')">Recettes de pesage</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           id="reva-tab"
           data-toggle="tab"
           href="#reva"
           role="tab"
           aria-controls="profile"
           aria-selected="false"
           @click="selection('rair')">Recettes d'aire de stationnement</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           id="edr-tab"
           data-toggle="tab"
           href="#edr"
           role="tab"
           aria-controls="profile"
           aria-selected="false"
           @click="selection('edr')">Situation des ressources du FER du {{ periode }}</a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link"
           id="variation-tab"
           data-toggle="tab"
           href="#variation"
           role="tab"
           aria-controls="profile"
           aria-selected="false"
           @click="selection('rva')">Variation des redevances</a>
      </li> -->
    </ul>
    <div class="tab-content"
         id="myTabContent">
      <div class="tab-pane fade show active"
           id="levee"
           role="tabpanel"
           aria-labelledby="home-tab">
        <RctLevee :comptes="banqueSansPage"
                  :extYear="year"
                  v-if="showing=='levee'"/>
      </div>
      <div class="tab-pane fade"
           id="raff"
           role="tabpanel"
           aria-labelledby="profile-tab">
        <RctAffectee :extYear="year"
                     v-if="showing=='ra'"/>
      </div>
      <!-- tab's content for redevance peage -->
      <div class="tab-pane fade"
           id="redp"
           role="tabpanel"
           aria-labelledby="profile-tab">
        <RctRedPeage :extYear="year"
                     v-if="showing=='rps'"/>
      </div>
      <!-- tab's content for revenu aire de stationnement -->
      <div class="tab-pane fade"
           id="reva"
           role="tabpanel"
           aria-labelledby="profile-tab">
        <RctRevAire :extYear="year"
                    v-if="showing=='rair'"/>
      </div>
      <!-- end content -->
      <!-- tab's content for recette peage -->
      <div class="tab-pane fade"
           id="recp"
           role="tabpanel"
           aria-labelledby="profile-tab">
        <RctPeage :extYear="year"
                  v-if="showing=='rp'"/>
      </div>
      <div class="tab-pane fade"
           id="edr"
           role="tabpanel"
           aria-labelledby="profile-tab">
        <EtatDesRessources :extYear="year"
                           v-if="showing=='edr'"/>
      </div>
      <div class="tab-pane fade"
           id="variation"
           role="tabpanel"
           aria-labelledby="profile-tab">
        <RctVariation :extYear="year"
                      v-if="showing=='rva'"/>
      </div>
      <!-- end content  -->
    </div>
    <!-- Modal donnée historique-->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="col-11 mx-auto">
              <div class="card title-card">
                <div class="card-body text-center title">
                  Importer données statistiques
                </div>
              </div>
            </div>
            <!-- <h5 class="modal-title"
                id="exampleModalLabel">Importer données historiques</h5> -->
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-row">
                <div class="col-md-11 form-group">
                  <label for=""
                  >Peage/Pesage</label
                  >
                  <div class="custom-file">
                    <label class="custom-file-label">
                      {{ inputFiles.donnees_histo }}
                    </label>

                    <input type="file"
                           name="etat_comptable"
                           class="custom-file-input"
                           id="donnees_histo"
                           ref="donnees_histo"
                           v-on:change="handleFileUpload('donnees_histo')"
                           accept=".xlsx,.csv" />
                    <span v-if="form.errors().has('donnees_histo')"
                          v-text="form.errors().get('donnees_histo')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading"/>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-f-blue"
                    @click="templateDownload('PeagePesage.xlsx')">Télécharger un template</button>
            <button type="button"
                    class="btn btn-f-blue"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-f-blue"
                    :disabled="url == ''"
                    @click="submit">Importer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
/* .table tbody td,
.table tfoot td,
.table thead th{
  font-size: 0.48em;
}
form.form-inline input,
form.form-inline button,
form.form-inline select,
li.nav-item{
  font-size: 0.6em;
} */
form.form-inline input{
  height: fit-content;
}
</style>
<script>
import RctPeage from "./recettes/RecettePeage"
import RctAffectee from "./recettes/RecetteAffectee"
import RctLevee from "./recettes/LeveeFond"
import RctRedPeage from "./recettes/RedevancePeage"
import RctRevAire from "./recettes/RevenusAireStationnement"
import EtatDesRessources from "./recettes/EtatRessources"
import { mapActions, mapGetters,mapMutations } from "vuex"
import form from "vuejs-form"
import utils from "@/assets/js/utils"
import Notif from "@/components/shared/Toast"
import Loader from "@/components/shared/Spinner"

export default {
  name: "Recettes",
  components: {
    RctPeage,
    RctAffectee,
    RctLevee,
    RctRedPeage,
    RctRevAire,
    EtatDesRessources,
    Loader,
    Notif,
  },
  data: ()=>({
    annee_debut: new Date().getFullYear()+"-01-01",
    annee_fin: new Date().getFullYear()+"-12-31",
    base:"CONSOLIDEE",
    consultation:"MENSUELLE",
    year:{
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",
      base:"CONSOLIDEE",
      consultation:"MENSUELLE",
    },
    periode:"01-01 au 31-12 " + new Date().getFullYear(),
    showing:"levee",
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    uploadfile :"",
    listExtensionFiles: [],
    user: "",
    url:"",
    loading:false,
    inputFiles: {  
      donnees_histo:"" 
    },
    form: form({
      donnees_histo:"" 
    })
      .rules({
        donnees_histo:"required" 
      })
      .messages({
        donnees_histo:"Ce champ est requis" 
      }),
  }),
  created() {
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    this.listExtensionFiles = utils.formaFichierAccepte
    this.listBanques()
  },
  watch: {
    msgSuccessImportPeagePesage(){
      if (this.msgSuccessImportPeagePesage) {
        this.notif.message = this.msgSuccessImportPeagePesage
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setFiles("")
            this.setMsgSuccessImportPeagePesage("")
            this.$refs["modal_button"].click()
          }.bind(this),
          3000
        )
      }
    },
    msgFailImportPeagePesage(){
      if (this.msgFailImportPeagePesage) {
        this.notif.message = this.msgFailImportPeagePesage
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMsgFailImportPeagePesage("")
            this.$refs["modal_button"].click()
          }.bind(this),
          3000
        )
      }
    },
    files() {
      if (this.files && this.files["url"]) {
        this.form[this.uploadfile] = process.env.VUE_APP_UPLOAD + this.files["url"]
        this.url = this.form[this.uploadfile]
        this.loading=false
      }
    }
  },
  computed: {
    ...mapGetters([ "banqueSansPage","msgSuccessImportPeagePesage","msgFailImportPeagePesage","files"])
  },
  methods: {
    ...mapActions(["listBanques","importPeagePesage","saveFile",]),
    ...mapMutations(["setMsgSuccessImportPeagePesage","setMsgFailImportPeagePesage","setFiles",]),
    changeYear() {
      this.year.annee_debut=this.annee_debut
      this.year.annee_fin=this.annee_fin
      this.year.base=this.base
      this.year.consultation=this.consultation

      var save = this.showing
      var split_annee_debut=this.annee_debut.split("-")
      var split_annee_fin=this.annee_fin.split("-")
      if (split_annee_debut[0] == split_annee_fin[0]) {
        this.periode= split_annee_debut[2]+"-"+split_annee_debut[1]+" au "+split_annee_fin[2]+"-"+split_annee_fin[1]+"-"+split_annee_fin[0]
      }else{
        this.periode= split_annee_debut[2]+"-"+split_annee_debut[1]+"-"+split_annee_debut[0]+" au "+split_annee_fin[2]+"-"+split_annee_fin[1]+"-"+split_annee_fin[0]
      }
      // if (this.showing!="levee") {
      this.showing=""
      setTimeout(
        function() {
          this.showing = save
        }.bind(this),
        500
      )
      // }
    },
    selection(key){
      this.showing=key
    },
    handleFileUpload(filename) {
      this.inputFiles[filename] = this.$refs[filename].files[0].name
      // this.uploadfile = filename
      const extension = this.inputFiles[filename].split(".")[1]

      if (this.listExtensionFiles.includes(extension)) {
        // this.loading[filename] = true
        // this.disabled = true
        this.loading= true
        this.uploadfile = filename
        this.saveFile(this.$refs[filename].files[0])
      } else {
        this.inputFiles[filename] = ""
        this.notif.message = "Mauvais format du fichier !!"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          5000
        )
      }
      // this.selectedFile = this.$refs[filename].files[0]
      // this.saveFile(this.$refs[filename].files[0])
    },
    submit() {
      this.importPeagePesage({url:this.url})
    },
    templateDownload(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_APIURL+"files/excel/FichiersModel/"+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    },
  }
}
</script>
