<template>
  <div class="inner_container">
    <div class="row mt-4">
      <div class="col-md-2">
        <button class="btn btn-round btn-blue"
                @click="generateReport()"> Imprimer </button>
      </div>
      <div class="row mb-4">
        <form class="form-inline mx-auto">
          <div class="form-group">
            <label for="">Base: </label>
            <select class="form-control mx-2"
                    name="" 
                    id=""
                    v-model="year.base"
                    @change="changeYear(year)">
              <option value="SIEGE">Siège</option>
              <option value="PEAGE">Péage</option>
              <option value="PESAGE">Pesage</option>
              <option value="AIRE_STATIONNEMENT">Aire de stationnement</option>
              <option value="CONSOLIDEE">Consolidée</option>
            </select>
          </div>
        </form>
      </div>
    </div>
    <!-- ///////////////////////////////////////////////////////////////////////// ZONE IMPRIMABLE ///////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- Vue-html2pdf -->
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1400"
                  filename="Situation_des_ressources"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"

                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf"
                  v-if="loaded==true">
      <div slot="pdf-content">
        <div class="row mt-3">
          <div class="col-3 ml-4">
            <img src="../../../assets/img/logo.svg" />
          </div>
          <div class="col-6">
          </div>
          <div class="col-2 ml-4">
            {{ dateJour }}
          </div>
        </div>
        <div class="row mt-3 justify-content-center">
          <div class="col-8">
            <div class="card title-card">
              <div class="card-body text-center title">
                Situation des ressources {{year.base }} du {{ year.annee_debut }} au {{ year.annee_fin }}
              </div>
            </div>
          </div>
        </div>
        <div style="width: 95%;margin: auto;">
          <div class="col-11">
            <table class="table table-blue mt-4">
              <thead v-if="year.periode=='MENSUELLE'">
                <tr>
                  <th style="border-top: none;"></th>
                  <th class="tht-dark-blue text-center"
                      colspan="13"> MONTANTS DU TIRAGE</th>
                </tr>
                <tr>
                  <th class="tht-blue"
                      style="width: 13%;">NATURE DES SOMMES REÇUES</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[0]">Janvier</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[1]">Février</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[2]">Mars</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[3]">Avril</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[4]">Mai</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[5]">Juin</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[6]">Juillet</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[7]">Août</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[8]">Septembre</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[9]">Octobre</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[10]">Novembre</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[11]">Décembre</th>
                  <th class="tht-blue text-center">TOTAL</th>
                </tr>
              </thead>
              <!-- AFFICHAGE ANNUEL -->
              <thead v-if="year.periode=='ANNUELLE'">
                <tr>
                  <th class="tht-blue"
                      style="width: 13%;">NATURE DES SOMMES REÇUES</th>
                  <template v-for="(annee,akey) in etatAnnuel[0].periode">
                    <th class="tht-blue text-center"
                        :key="'n3-'+akey">{{annee}}</th>
                  </template>
                  <th class="tht-blue text-center">TOTAL</th>
                </tr>
              </thead>
              <tbody v-if="year.periode=='MENSUELLE'">
                <template v-for="(ressource,reskey) in  etatMensuel">
                  <template v-for="(niveau4, nivkey) in ressource.niveau_4">
                    <tr :key="'n4-'+niveau4.libelle+nivkey">
                      <td class="tht-blue">{{ niveau4.libelle }}</td>
                      <td v-for="(montant,montkey) in niveau4.montants"
                          :key="montkey"
                          class="text-right">{{ Math.round(Number(montant)).toLocaleString() }}</td>
                    </tr>
                  </template>
                  <tr :key="'title'+reskey">
                    <td class="tht-dark-blue">{{ ressource.libelle }}</td>
                    <td v-for="(total,totkey) in ressource.total_n3"
                        :key="'tot-'+totkey" 
                        class="tht-dark-blue text-right">{{ Math.round(Number(total)).toLocaleString() }}</td>
                  
                  </tr>
                </template>
              </tbody>
              <!-- AFFICHAGE ANNUEL -->

              <tbody v-if="year.periode=='ANNUELLE'">
                <template v-for="(ressource,reskey) in  etatAnnuel">
                  <template v-for="(niveau4, nivkey) in ressource.niveau_4">
                    <tr :key="nivkey">
                      <td class="tht-blue">{{ niveau4.libelle }}</td>
                      <td v-for="(montant,montkey) in niveau4.montants"
                          :key="montkey"
                          class="text-right">{{ Math.round(Number(montant)).toLocaleString() }}</td>
                    </tr>
                  </template>
                  <tr :key="'title'+reskey">
                    <td class="tht-dark-blue">{{ ressource.libelle }}</td>
                    <td v-for="(total,totkey) in ressource.total_n3"
                        :key="totkey" 
                        class="tht-dark-blue text-right">{{ Math.round(Number(total)).toLocaleString() }}</td>
                  
                  </tr>
                </template>
              </tbody>
              <!-- AFFICHAGE TOTAL GENERAL -->
              <tfoot>
                <tr>
                  <td class="tht-dark-blue">TOTAL GENERAL</td>
                  <td v-for="(general,genkey) in  total_general"
                      :key="genkey"
                      class="tht-dark-blue text-right">{{ Math.round(Number(general)).toLocaleString() }}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </vue-html2pdf>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    <table class="table table-blue mt-4">
      <thead v-if="year.periode=='MENSUELLE'">
        <tr>
          <th style="border-top: none;"></th>
          <th class="tht-dark-blue text-center"
              colspan="13"> MONTANTS DU TIRAGE</th>
        </tr>
        <tr>
          <th class="tht-blue"
              style="width: 13%;">NATURE DES SOMMES REÇUES</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[0]">Janvier</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[1]">Février</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[2]">Mars</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[3]">Avril</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[4]">Mai</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[5]">Juin</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[6]">Juillet</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[7]">Août</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[8]">Septembre</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[9]">Octobre</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[10]">Novembre</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[11]">Décembre</th>
          <th class="tht-blue text-center">TOTAL</th>
        </tr>
      </thead>
      <!-- AFFICHAGE ANNUEL -->
      <thead v-if="year.periode=='ANNUELLE'">
        <tr>
          <th class="tht-blue"
              style="width: 13%;">NATURE DES SOMMES REÇUES</th>
          <template v-for="(annee,akey) in etatAnnuel[0].periode">
            <th class="tht-blue text-center"
                :key="'n3-'+akey">{{annee}}</th>
          </template>
          <th class="tht-blue text-center">TOTAL</th>
        </tr>
      </thead>
      <tbody v-if="year.periode=='MENSUELLE'">
        <template v-for="(ressource,reskey) in  etatMensuel">
          <template v-for="(niveau4, nivkey) in ressource.niveau_4">
            <tr :key="'n4-'+niveau4.libelle+nivkey"
                v-if="year.base!='CONSOLIDEE'">
              <td class="tht-blue">{{ niveau4.libelle }}</td>
              <td v-for="(montant,montkey) in niveau4.montants"
                  :key="montkey"
                  class="text-right">{{ Math.round(Number(montant)).toLocaleString() }}</td>
            </tr>
          </template>
          <tr :key="'title'+reskey">
            <td :class="year.base=='CONSOLIDEE'?'':'tht-dark-blue'">{{ ressource.libelle }}</td>
            <td v-for="(total,totkey) in ressource.total_n3"
                :key="'tot-'+totkey" 
                class="text-right"
                :class="year.base=='CONSOLIDEE'?'':'tht-dark-blue'">{{ Math.round(Number(total)).toLocaleString() }}</td>

          </tr>
        </template>
      </tbody>
      <!-- AFFICHAGE ANNUEL -->
      
      <tbody v-if="year.periode=='ANNUELLE'">
        <template v-for="(ressource,reskey) in  etatAnnuel">
          <template v-for="(niveau4, nivkey) in ressource.niveau_4">
            <tr :key="nivkey"
                v-if="year.base!='CONSOLIDEE'">
              <td class="tht-blue">{{ niveau4.libelle }}</td>
              <td v-for="(montant,montkey) in niveau4.montants"
                  :key="montkey"
                  class="text-right">{{ Math.round(Number(montant)).toLocaleString() }}</td>
            </tr>
          </template>
          <tr :key="'title'+reskey">
            <td :class="year.base=='CONSOLIDEE'?'':'tht-dark-blue'">{{ ressource.libelle }}</td>
            <td v-for="(total,totkey) in ressource.total_n3"
                :key="totkey" 
                class="text-right"
                :class="year.base=='CONSOLIDEE'?'':'tht-dark-blue'">{{ Math.round(Number(total)).toLocaleString() }}</td>

          </tr>
        </template>
      </tbody>
      <!-- AFFICHAGE TOTAL GENERAL -->
      <tfoot>
        <tr>
          <td class="tht-dark-blue">TOTAL GENERAL</td>
          <td v-for="(general,genkey) in  total_general"
              :key="genkey"
              class="tht-dark-blue text-right">{{ Math.round(Number(general)).toLocaleString() }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import VueHtml2pdf from 'vue-html2pdf'
export default {
  name: "RctLevee",
  components:{
    VueHtml2pdf
  },
  props:["extYear"],
  data: () => ({
    year: {
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",
      periode:"MENSUELLE",
      base:"CONSOLIDEE",  
    },
    dateJour:"",
    etatMensuel:[],
    etatAnnuel:"",
    long:0,
    annee:{
      debut:"",
      fin:""
    },
    loaded:false,
    monthLabel:[false,false,false,false,false,false,false,false,false,false,false,false,],
    total:{
      siege:[0,0,0,0,0,0,0,0,0,0,0,0,0],
      concession:[0,0,0,0,0,0,0,0,0,0,0,0,0],
      emprunt:[0,0,0,0,0,0,0,0,0,0,0,0,0],
      total:[0,0,0,0,0,0,0,0,0,0,0,0,0],
    },
    total_general:[]
  }),
  created() {
    var jour = new Date()
    if (jour.getDate() < 10) {
      this.dateJour +="0" 
    }
    this.dateJour+=jour.getDate()+"-"
    if (jour.getMonth() < 9) {
      this.dateJour +="0"
    }
    this.dateJour+=(jour.getMonth()+1)+"-"
    this.dateJour+= jour.getFullYear()

    this.setEtatRessources("")
    this.year.annee_debut=this.extYear.annee_debut
    this.year.annee_fin=this.extYear.annee_fin
    this.year.base=this.extYear.base
    this.year.periode=this.extYear.consultation

    // var fin_annee=this.extYear.annee_fin.split("-")
    // var debut_annee=this.extYear.annee_debut.split("-")
    // if (fin_annee[0]==debut_annee[0]) {
    //   this.year.periode="MENSUELLE"
    // }else{
    //   this.year.periode="ANNUELLE"
    // }
    this.loadEtatRessources(this.year)
  },
  watch: {
    //TODO la requette renvoi une erreur 500 lorseque l'annee 2021 change
    arRedevanceV() {
    },
    extYear(){
      if (this.extYear) {
        this.changeYear(this.extYear)
      }
    },
    
    etatRessources(){
      if (this.etatRessources) {
        // console.log(this.etatRessources)
        this.loaded=true
        if(this.year.periode=="MENSUELLE"){
          var fin_annee=this.year.annee_fin.split("-")
          var debut_annee=this.year.annee_debut.split("-")
          this.annee.debut= debut_annee[2]+"-"+debut_annee[1]+"-"+debut_annee[0]
          this.annee.fin= fin_annee[2]+"-"+fin_annee[1]+"-"+fin_annee[0]
          for (let index = debut_annee[1]-1; index < fin_annee[1]; index++) {
            this.monthLabel[index]=true            
          }
          this.etatMensuel=[]
          var count=0
          this.etatRessources.niveau_3.forEach(niveau3 => {
            if (niveau3.libelle.split(" ")[0] == "EMPRUNT") {
              if (this.year.base == "CONSOLIDEE") {
                this.etatMensuel.push({libelle:niveau3.libelle,niveau_4:[],total_n3:[]})
                var counter=0
                niveau3.niveau_4.forEach(element => {
                  this.etatMensuel[count].niveau_4.push({libelle:element.libelle,montants:[]})
                  var total_n4=0
                  for (let index = debut_annee[1]-1; index < fin_annee[1]; index++) {
                    this.etatMensuel[count].niveau_4[counter].montants.push(element.montants[0][index])
                    total_n4+=element.montants[0][index]
                  }
                  this.etatMensuel[count].niveau_4[counter].montants.push(total_n4)
                  counter++
                })
                for (let index = 0; index < this.etatMensuel[count].niveau_4[0].montants.length; index++) {
                  this.etatMensuel[count].total_n3.push(0)
                }
                this.etatMensuel[count].niveau_4.forEach(niveau4 => {
                  for (let index = 0; index < niveau4.montants.length; index++) {
                    this.etatMensuel[count].total_n3[index]+=niveau4.montants[index]                
                  }
                }) 
                count++
              } else {
                if (this.year.base==niveau3.base) {
                  this.etatMensuel.push({libelle:niveau3.libelle,niveau_4:[],total_n3:[]})
                  counter=0
                  niveau3.niveau_4.forEach(element => {
                    console.log(count)
                    this.etatMensuel[count].niveau_4.push({libelle:element.libelle,montants:[]})
                    var total_n4=0
                    for (let index = debut_annee[1]-1; index < fin_annee[1]; index++) {
                      this.etatMensuel[count].niveau_4[counter].montants.push(element.montants[0][index])
                      total_n4+=element.montants[0][index]
                    }
                    this.etatMensuel[count].niveau_4[counter].montants.push(total_n4)
                    // console.log(counter)
                    counter++
                  })
                  for (let index = 0; index < this.etatMensuel[count].niveau_4[0].montants.length; index++) {
                    this.etatMensuel[count].total_n3.push(0)
                  }
                  this.etatMensuel[count].niveau_4.forEach(niveau4 => {
                    for (let index = 0; index < niveau4.montants.length; index++) {
                      this.etatMensuel[count].total_n3[index]+=niveau4.montants[index]                
                    }
                  })
                  count++
                }
              }
            }
          }) 
          console.log(this.etatMensuel)
          // INITIALISATION DU NOMBRE DE COLONNE DU TABLEAU DU TOTAL GENERAL
          this.total_general=[]
          for (let index = 0; index < this.etatMensuel[0].niveau_4[0].montants.length; index++) {
            this.total_general.push(0)
          }
          // ALIMENTATION DU TABLEAU DU TOTAL GENERAL
          this.etatMensuel.forEach(niveau3 => {
            niveau3.niveau_4.forEach(niveau4 => {
              for (let index = 0; index < niveau4.montants.length; index++) {
                this.total_general[index] += niveau4.montants[index]
              }
            })
          })
          console.log(this.total_general)
        }else{
          this.etatAnnuel=[]
          count=0
          this.etatRessources.niveau_3.forEach(niveau3 => {
            if (niveau3.libelle.split(" ")[0] == "EMPRUNT") {
              if (this.year.base == "CONSOLIDEE") {
                this.etatAnnuel.push({libelle:niveau3.libelle,niveau_4:[],total_n3:[],periode:""})
                var counter=0
                niveau3.niveau_4.forEach(element => {
                  this.etatAnnuel[count].niveau_4.push({libelle:element.libelle,montants:[]})
                  this.etatAnnuel[count].periode = element.annees[0]
                  var total_n4=0
                  for (let index = 0; index < element.montants[0].length; index++) {
                    this.etatAnnuel[count].niveau_4[counter].montants.push(element.montants[0][index])
                    total_n4+=element.montants[0][index]
                  }
                  this.etatAnnuel[count].niveau_4[counter].montants.push(total_n4)
                  counter++
                })
                for (let index = 0; index < this.etatAnnuel[count].niveau_4[0].montants.length; index++) {
                  this.etatAnnuel[count].total_n3.push(0)
                }
                this.etatAnnuel[count].niveau_4.forEach(niveau4 => {
                  for (let index = 0; index < niveau4.montants.length; index++) {
                    this.etatAnnuel[count].total_n3[index]+=niveau4.montants[index]                
                  }
                })
                count++
              } else {
                if (this.year.base==niveau3.base) {
                  this.etatAnnuel.push({libelle:niveau3.libelle,niveau_4:[],total_n3:[],periode:""})
                  counter=0
                  niveau3.niveau_4.forEach(element => {
                    this.etatAnnuel[count].niveau_4.push({libelle:element.libelle,montants:[]})
                    this.etatAnnuel[count].periode = element.annees[0]
                    var total_n4=0
                    for (let index = 0; index < element.montants[0].length; index++) {
                      this.etatAnnuel[count].niveau_4[counter].montants.push(element.montants[0][index])
                      total_n4+=element.montants[0][index]
                    }
                    this.etatAnnuel[count].niveau_4[counter].montants.push(total_n4)
                    counter++
                  })
                  for (let index = 0; index < this.etatAnnuel[count].niveau_4[0].montants.length; index++) {
                    this.etatAnnuel[count].total_n3.push(0)
                  }
                  this.etatAnnuel[count].niveau_4.forEach(niveau4 => {
                    for (let index = 0; index < niveau4.montants.length; index++) {
                      this.etatAnnuel[count].total_n3[index]+=niveau4.montants[index]                
                    }
                  })
                  count++
                }
              }
            }
          })
          console.log(this.etatAnnuel) 
          // INITIALISATION DU NOMBRE DE COLONNE DU TABLEAU DU TOTAL GENERAL
          this.total_general=[]
          for (let index = 0; index < this.etatAnnuel[0].niveau_4[0].montants.length; index++) {
            this.total_general.push(0)
          }
          // ALIMENTATION DU TABLEAU DU TOTAL GENERAL
          this.etatAnnuel.forEach(niveau3 => {
            niveau3.niveau_4.forEach(niveau4 => {
              for (let index = 0; index < niveau4.montants.length; index++) {
                this.total_general[index] += niveau4.montants[index]
              }
            })
          })
          console.log(this.total_general)
        }
      }
        
    }
  },
  computed: {
    ...mapGetters(["etatRessources","msgFailEtatRessources"])
  },
  methods: {
    ...mapActions(["loadEtatRessources"]),
    ...mapMutations(["setEtatRessources","setMsgFailEtatRessources"]),
    changeYear(year) {
      this.year=year
      this.setEtatRessources("")
      this.loaded=false
      this.loadEtatRessources(this.year)
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    }
  }
}
</script>
