<template>
  <div class="inner_container">
   
    <div class="row my-4">
      <div class="col-md-7">
        <table class="table table-blue">
          <thead>
            <tr>
              <th class="th-blue text-center">Rubriques</th>
              <th class="th-blue text-center">Réalisation au {{ year.annee_fin }}</th>
            </tr>
          </thead>
          <tbody v-if="yearData!=null">
            <tr v-for="(year,ykey) in yearData"
                :key="ykey">
              <td scope="row">{{ year.rubrique }}</td>
              <td class="text-right">{{ Math.round(Number(year.realisation)).toLocaleString() }} FCFA</td>
            </tr>
            <tr class="tht-blue d-content-dark-blue">
              <td scope="row">TOTAL</td>
              <td class="text-right">{{ Math.round(Number(total_realisation)).toLocaleString() }} FCFA</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td scope="row"
                  colspan="2">Aucune donnée enregistrée</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-5 analytique">
        <div class="progress row"
             style="height:6vh;"
             v-if="ressourcesAffectees">
          <div class="progress-bar bg-info progress-bar-striped progress-bar-animated"
               role="progressbar"
               :style="'width:' + ressourcesAffectees.budget_cible!=0 ?(total_realisation*100)/ressourcesAffectees.budget_cible:0 + '%'"
               :aria-valuenow="ressourcesAffectees.budget_cible!=0 ?(total_realisation*100)/ressourcesAffectees.budget_cible:0 "
               aria-valuemin="0"
               aria-valuemax="100">
            {{ Math.round((ressourcesAffectees.budget_cible!=0 ?(total_realisation*100)/ressourcesAffectees.budget_cible:0 ) * 100) / 100 }}%
          </div>
        </div>
        <div class="reliq row mt-3 py-3">
          <div class="col-6">
            <p>Budget Cible</p>
            <p>Flux Reçus</p>
            <p>Reliquat du {{year.annee_debut}} au {{year.annee_fin}}</p>
          </div>
          <div v-if="ressourcesAffectees"
               class="col-6 text-right">
            <p>{{ Math.round(Number(ressourcesAffectees.budget_cible)).toLocaleString() }} FCFA</p>
            <p>{{ Math.round(Number(total_realisation)).toLocaleString() }} FCFA</p>
            <p :class="color">{{ (Math.round(Number(total_realisation - ressourcesAffectees.budget_cible))).toLocaleString() }} FCFA</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="th-blue text-center"></th>
            <th class="tht-blue text-center"
                :colspan="ressources[0].annee.length"
                style="border-right: 2px solid #dbdbdb;">Montants (FCFA) de la période en cours</th>
            <th class="tht-blue text-center"
                :colspan="ressources[0].annee.length">Montants (FCFA) de la période antérieure</th>
            <th class="th-blue text-right"></th>
          </tr>
          <tr>
            <th class="th-blue text-center">Mois</th>
            <template v-for="(libelle,libkey) in listRubrique">
              <th class="th-blue text-right"
                  :key="libkey"
                  :style="libkey==(listRubrique.length-1)?'border-right: 2px solid #dbdbdb':''">{{ libelle.libelle}}</th>            
            </template>
            <template v-for="(libelle,libkey) in listRubrique">
              <th class="th-blue text-right"
                  :key="'n-1='+libkey">{{ libelle.libelle }}</th>

            </template>
            <th class="th-blue text-right">Variations</th>
          </tr>
        </thead>
        <tbody v-if="ressources != null">
          <tr v-for="(ressource, rekey) in ressources"
              :key="rekey"
              class="text-center">
            <td>{{ ressource.mois }}</td>
            <td class="text-right"
                v-for="(annee,ankey) in ressource.annee"
                :key="ankey"
                :style="ankey==(listRubrique.length-1)?'border-right: 2px solid #dbdbdb':''">{{ Math.round(Number(annee)).toLocaleString() }}</td>
            <td class="text-right"
                v-for="(annee_n1,an1key) in ressource.annee_n1"
                :key="'n-1='+an1key">{{ Math.round(Number(annee_n1)).toLocaleString() }}</td>
            <td class="text-right">{{ (Math.round(Number(ressource.variation) * 100) / 100).toLocaleString() }}%</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="text-center">
            <td colspan="4">Aucune donnée chargée...</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="tht-dark-blue">TOTAL</td>
            <td class="text-right tht-dark-blue"
                v-for="(montant, montkey) in totalMonth.annee"
                :key="montkey"
                :style="montkey==(totalMonth.annee.length-1)?'border-right: 2px solid #dbdbdb':''">{{Math.round(montant).toLocaleString()}}</td>
            <td class="text-right tht-dark-blue"
                v-for="(montant, mont1key) in totalMonth.annee_n1"
                :key="'annee-1='+mont1key">{{Math.round(montant).toLocaleString()}}</td>
            <td class="text-right tht-dark-blue">{{(Math.round(totalMonth.variation*100)/100).toLocaleString()}}%</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<style>
.analytique{
  font-size: 0.6em;
}
.bad-news{
  color:#ff0000;
}
.good-news{
  color:#24b13c;
}
</style>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex"
export default {
  name: "RctAffectee",
  props:["extYear"],
  data: () => ({
    year: {
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",
      periode:"MENSUELLE",
      base:"",
      nature:"Ressources affectées"  
    },
    totalMonth:{
      annee:0,
      annee_n1:0,
      variation:0
    },
    listRubrique:[],
    total_realisation:0,
    color:"",
    yearData: null,
    ressources: null,
    pourcentage: 0,
  }),
  created() {
    this.year.annee_debut=this.extYear.annee_debut
    this.year.annee_fin=this.extYear.annee_fin
    this.year.base=this.extYear.base
    this.year.periode=this.extYear.consultation
    // var fin_annee=this.extYear.annee_fin.split("-")
    // var debut_annee=this.extYear.annee_debut.split("-")
    // if (fin_annee[0]==debut_annee[0]) {
    //   this.year.periode="MENSUELLE"
    // }else{
    //   this.year.periode="ANNUELLE"
    // }
    this.RessourcesAffectees(this.year)
  },
  watch: {
    // Ancien
    // ressourcesAffectees(){
    //   if (this.ressourcesAffectees) {
    //     console.log(this.ressourcesAffectees)

    //     this.yearData=this.ressourcesAffectees.analyse
    //     console.log(this.yearData)
    //     this.ressources=this.ressourcesAffectees.tableau
    //     this.pourcentage= (this.yearData.total_flux*100)/this.yearData.budget_cible
    //   }
    // },
    // Ancien Nouveau 
    // ressourcesAffectees(){
    //   if (this.ressourcesAffectees) {
    //     console.log(this.ressourcesAffectees)
    //     this.yearData=[]
    //     var count=0
    //     if (this.ressourcesAffectees.totaux[0].realisation - this.ressourcesAffectees.totaux[0].budget > 0) {
    //       this.color = "good-news"
    //     } else {
    //       this.color = "bad-news"
    //     }
    //     this.ressourcesAffectees.rubriques[0].libelle.forEach(rubrique => {
    //       this.yearData.push({libelle:rubrique, realisation:Number(this.ressourcesAffectees.rubriques[0].montant_rubrique[count])})
    //       count++
    //     })
    //     console.log(this.yearData)
    //     var mois=["Janvier","Fevrier","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"]
    //     this.ressources=[]
    //     this.totalMonth={
    //       annee:0,
    //       annee_n1:0,
    //       variation:0
    //     }
    //     for (let index = 0; index < this.ressourcesAffectees.periodes[0].mois.length; index++) {
    //       this.ressources.push({
    //         mois:mois[index],
    //         annee:this.ressourcesAffectees.periodes[0].montant_n[index],
    //         annee_n1:this.ressourcesAffectees.periodes[0]["montant_n-1"][index],
    //         variation:0  
    //       })
    //       console.log(this.ressources)
    //       this.ressources[index].variation += this.ressources[index].annee_n1 !=0 ?((this.ressources[index].annee/this.ressources[index].annee_n1)-1)*100 : 0
    //       this.totalMonth.annee += this.ressources[index].annee
    //       this.totalMonth.annee_n1 += this.ressources[index].annee_n1
    //       this.totalMonth.variation += this.ressources[index].variation
    //     }
    //   }
    // },

    ressourcesAffectees(){
      if (this.ressourcesAffectees) {
        console.log(this.ressourcesAffectees)
        this.yearData=[]
        this.total_realisation=0
        this.yearData=this.ressourcesAffectees.analyse
        this.yearData.forEach(analyse => {
          this.total_realisation += analyse.realisation          
        })
        if (this.total_realisation - this.ressourcesAffectees.budget_cible > 0) {
          this.color = "good-news"
        } else {
          this.color = "bad-news"
        }
        var mois=["Janvier","Fevrier","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"]
        this.listRubrique=[]
        this.listRubrique=this.ressourcesAffectees.tableau_n[0].rubriques

        this.ressources=[]
        this.totalMonth={
          annee:[],
          annee_n1:[],
          variation:0
        }
        for (let index = 0; index < this.ressourcesAffectees.tableau_n[0].montants.length; index++) {
          this.totalMonth.annee.push(0)
          this.totalMonth.annee_n1.push(0)
        }
        console.log(this.totalMonth)
        for (let index = 0; index < this.ressourcesAffectees.tableau_n.length; index++) {
          this.ressources.push({
            mois : mois[index],
            annee : this.ressourcesAffectees.tableau_n[index].montants,
            annee_n1 : this.ressourcesAffectees.tableau_n1[index].montants,
            variation:0  
          })
        }
        this.ressources.forEach(ressource => {
          var total_mois_n=0
          var total_mois_n1=0
          for (let index = 0; index < ressource.annee.length; index++) {
            this.totalMonth.annee[index] += ressource.annee[index]
            this.totalMonth.annee_n1[index] += ressource.annee_n1[index]
            total_mois_n += ressource.annee[index]
            total_mois_n1 += ressource.annee_n1[index]
            console.log(ressource.annee[index])
          }  
          ressource.variation = total_mois_n1 != 0 ?((total_mois_n/total_mois_n1)-1)*100 : 0
          this.totalMonth.variation += ressource.variation
        })  
        console.log(this.ressources)

      }
    },

    extYear(){
      if (this.extYear) {
        this.changeYear(this.extYear)
        console.log(this.extYear)
      }
    }
  },
  computed: {
    ...mapGetters(["ressourcesAffectees",])
  },
  methods: {
    ...mapActions(["RessourcesAffectees",]),
    ...mapMutations(["setRessourcesAffectees", "setMsgFailRessourcesAffectees"]),
    changeYear(year) {
      this.setRessourcesAffectees("")
      this.year = year
      this.yearData = null
      this.ressources = null
      // var fin_annee=this.extYear.annee_fin.split("-")
      // var debut_annee=this.extYear.annee_debut.split("-")
      // if (fin_annee[0]==debut_annee[0]) {
      //   this.year.periode="MENSUELLE"
      // }else{
      //   this.year.periode="ANNUELLE"
      // }
      this.RessourcesAffectees(this.year)
    }
  }
}
</script>
