<template>
  <div class="inner_container">
    <!-- Ligne n°1 -->
    <div class="row mt-5"
         v-if="yearData ==null">
      <div class="col-md-10 mx-auto">
        <h5 class="text-center">Veuillez sélectionner une période contenant des données.</h5>
      </div>
    </div>
    <!-- Fin ligne n°1 -->
    <!-- Ligne n°2 -->
    <div class="row mt-4"
         v-if="yearData !=null">
      <div class="col-md-6">
        <table class="table table-blue">
          <thead>
            <tr>
              <th class="th-blue text-center">GARES</th>
              <th class="th-blue text-right">AU COMPTANT</th>
              <th class="th-blue text-right">PAR CARTE</th>
              <th class="th-blue text-right">TOTAL</th>
            </tr>
          </thead>
          <tbody v-if="yearData != null">
            <tr v-for="(recette, ykey) in yearData.tableau"
                :key="ykey">
              <td scope="row">{{ recette.gare }}</td>
              <td class="text-right">{{ Math.round(Number(recette.paiement_direct)).toLocaleString() }} FCFA</td>
              <td class="text-right">{{ Math.round(Number(recette.paiement_carte)).toLocaleString() }} FCFA</td>
              <td class="text-right">{{ Math.round(Number(recette.recettes)).toLocaleString() }} FCFA</td>
            </tr>
            <tr class="tht-blue d-content-dark-blue">
              <td scope="row">TOTAUX</td>
              <td class="text-right">{{ Math.round(Number(total.direct)).toLocaleString() }} FCFA</td>
              <td class="text-right">{{ Math.round(Number(total.carte)).toLocaleString() }} FCFA</td>
              <td class="text-right">{{ Math.round(Number(total.recette)).toLocaleString() }} FCFA</td>
            </tr>
          </tbody>
          <tbody v-else>
            <td colspan="4"
                class="text-center">Aucune donnée disponible...</td>
          </tbody>
        </table>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12 analytique">
            <div class="progress row"
                 style="height:6vh;">
              <div class="progress-bar bg-info progress-bar-striped progress-bar-animated"
                   role="progressbar"
                   :style="'width:' + yearData.budget_cible!=0 ?(total.recette*100)/yearData.budget_cible:0 + '%'"
                   :aria-valuenow="yearData.budget_cible!=0 ?(total.recette*100)/yearData.budget_cible:0 "
                   aria-valuemin="0"
                   aria-valuemax="100">
                {{ Math.round((yearData.budget_cible!=0 ?(total.recette*100)/yearData.budget_cible:0 ) * 100) / 100 }}%
              </div>
            </div>
            <div class="reliq row mt-3 py-3">
              <div class="col-6">
                <p>Budget Cible</p>
                <p>Flux Reçus</p>
                <p>Reliquat du {{year.annee_debut}} au {{year.annee_fin}}</p>
              </div>
              <div v-if="yearData"
                   class="col-6 text-right">
                <p>{{ Math.round(Number(yearData.budget_cible)).toLocaleString() }} FCFA</p>
                <p>{{ Math.round(Number(total.recette)).toLocaleString() }} FCFA</p>
                <p :class="color">{{ (Math.round(Number(total.recette - yearData.budget_cible))).toLocaleString() }} FCFA</p>
              </div>
            </div>
          </div>
          <div class="text-center mb-5 mt-3"
               :class="width"
               style="height:300px;"
               v-if="yearData != null">
            <Pie :chartData="chartData"
                 :height="270"></Pie>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin ligne n°2 -->
    <!-- Début ligne n°3 -->
    <div class="row my-5"
         v-if="yearData !=null">
      <div class="col-md-4 mx-auto mt-3">
        <div class="form-row row">
          <div class="form-group col-md-6"><label for="">Choisissez la gare</label></div>
          <div class="form-group col-md-6">
            <select class="form-control"
                    name=""
                    id=""
                    v-model="gare"
                    @change="changeGare()">
              <option v-for="(gare,garekey) in listGare"
                      :key="garekey"
                      :value="gare">{{gare}}</option>
              <!-- <option value="SINGROBO">SINGROBO</option>
              <option value="THOMASSET">THOMASSET</option>
              <option value="MOAPE">MOAPE</option> -->
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-4 mx-auto mt-3">
        <div class="form-row row">
          <div class="form-group col-md-6"><label for="">Choisissez un axe</label></div>
          <div class="form-group col-md-6">
            <select class="form-control"
                    name=""
                    id=""
                    v-model="axe"
                    @change="changeAxe()">
              <option v-for="(axe,axekey) in listAxe"
                      :key="axekey"
                      :value="axe">{{axe}}</option>
              <!-- <option value="SINGROBO">SINGROBO</option>
              <option value="THOMASSET">THOMASSET</option>
              <option value="MOAPE">MOAPE</option> -->
            </select>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin ligne n°3 -->
    <!-- Début ligne n°4 -->
    <div class="row"
         v-if="yearData !=null">
      <table class="table table-striped table-blue">
        <thead>
          <tr v-if="gare!='TOUTES' && gare!=null">
            <th style="border-top:none"></th>
            <th colspan="3"
                class="text-center tht-blue">{{ gare }}</th>
          </tr>
          <template v-else>
            <tr v-if="axe!=null">
              <th style="border-top:none"></th>
              <th colspan="15"
                  class="text-center tht-dark-blue">{{ axe }}</th>
            </tr>
            <tr>
              <th colspan="2"
                  class="text-center tht-blue">Mois</th>
              <th v-for="(mois,moikey) in month"
                  :key="'mois'+moikey"
                  class="text-right tht-blue"> {{ mois.mois }}</th>
              <th class="text-right tht-dark-blue">TOTAUX</th>
            </tr>
          </template>
          
        </thead>
        <tbody v-if="gareData != null && (gare!='TOUTES' && gare!=null)">
          <tr class="text-center">
            <td >Mois</td>
            <td>Paiement au comptant</td>
            <td>Paiement par carte</td>
            <td class="tht-blue">SOUS-TOTAL</td>
          </tr>

          <tr v-for="(mois, mkey) in month"
              :key="mkey">
            <td >{{mois.mois}}</td>
            <td class="text-right">{{Math.round(Number(gareData.paiement_comptant[mkey])).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(gareData.paiement_carte[mkey])).toLocaleString()}}</td>
            <td class="text-right tht-blue">{{Math.round(Number(mois.total)).toLocaleString()}}</td>
          </tr>
          <tr class="tr-blue">
            <td >TOTAUX</td>
            <td class="text-right">{{ Math.round(Number(gareData.totaux.paiement_comptant)).toLocaleString() }}</td>
            <td class="text-right">{{ Math.round(Number(gareData.totaux.paiement_carte)).toLocaleString() }}</td>  
            <td class="tht-blue text-right">{{Math.round(Number(monthTotal)).toLocaleString() }}</td>
          </tr>
        </tbody>
        <tbody v-else-if="gareData != null && (gare=='TOUTES' || axe!=null)">       
          
          <template v-for="(gare,gkey) in gareData">
            <tr :key="gare.gare+'ligne1-col'+gkey">
              <td rowspan="3"
                  style="vertical-align: middle;text-align: center;border-right: 1px solid #dbdbdb;"
                  class="tht-blue">{{ gare.gare }}</td>
              <td>Paiement au comptant</td>
              <td v-for="n in month.length"
                  :key="'mois'+n+'gare'+gare.gare+'ligne1'"
                  class="text-right">
                {{Math.round(Number(gare.paiement_comptant[n-1])).toLocaleString()}}
              </td>
              <td class="text-right tht-dark-blue">
                {{Math.round(Number(gare.totaux.paiement_comptant)).toLocaleString()}}
              </td>
            </tr>
            <tr :key="gare.gare+'ligne2-col'+gkey">
              <td>Paiement par carte</td>
              <td v-for="n in month.length"
                  :key="'mois'+n+'gare'+gare.gare+'ligne2'"
                  class="text-right">
                {{Math.round(Number(gare.paiement_carte[n-1])).toLocaleString()}}
              </td>
              <td class="text-right tht-dark-blue">
                {{Math.round(Number(gare.totaux.paiement_carte)).toLocaleString()}}
              </td>
            </tr>
            <tr :key="gare.gare+'ligne3-col'+gkey">
              <td class="tht-blue">SOUS-TOTAL</td>
              <td v-for="(mois,monthkey) in month"
                  :key="'mois'+monthkey+'gare'+gare.gare+'ligne3'"
                  class="text-right tht-blue">
                {{Math.round(Number(mois.total[gkey])).toLocaleString()}}
              </td>
              <td class="text-right tht-blue">
                {{Math.round(Number(monthTotal[gkey])).toLocaleString()}}
              </td>
            </tr>
            
          </template>
          <tr>
            <td colspan="2"
                class="tht-dark-blue text-center">TOTAUX</td>
            <td v-for="(mois,monthkey) in month"
                :key="'totalmois'+monthkey+'lignetotal'"
                class="text-right tht-dark-blue">
              {{Math.round(Number(mois.total[gareData.length])).toLocaleString()}}
            </td>
            <td class="text-right tht-dark-blue">
              {{Math.round(Number(monthTotal[gareData.length])).toLocaleString()}}
            </td>
          </tr>
          <!-- <tr class="text-center">
            <td >Mois</td>
            <template v-for="a in gareData.length">
              <td :key="'title1'+a">Paiement au comptant</td>
              <td :key="'title2'+a">Paiement par carte</td>
              <td class="tht-dark-blue"
                  :key="'title3'+a">SOUS-TOTAL</td>
            </template>
            <td class="tht-blue">TOTAUX</td>
          </tr>

          <tr v-for="(mois, mkey) in month"
              :key="mkey">
            <td >{{mois.mois}}</td>
            <template v-for="(gareMontant,gmkey) in  gareData">
              <td class="text-right"
                  :key="'ligne1'+mkey+'col'+gmkey">{{Math.round(Number(gareMontant.paiement_comptant[mkey])).toLocaleString()}}</td>
              <td class="text-right"
                  :key="'ligne2'+mkey+'col'+gmkey">{{Math.round(Number(gareMontant.paiement_carte[mkey])).toLocaleString()}}</td>
              <td class="text-right tht-dark-blue"
                  :key="'ligne3'+mkey+'col'+gmkey">{{Math.round(Number(mois.total[gmkey])).toLocaleString()}}</td>
            </template>
            <td class="text-right tht-blue">{{Math.round(Number(mois.total[gareData.length])).toLocaleString()}}</td>
            
          </tr>
          <tr>
            <td class="tht-blue">TOTAUX</td>
            <template v-for="(gareTotal,gtkey) in gareData">
              <td class="text-right tht-blue"
                  :key="'total1'+gtkey">{{ Math.round(Number(gareTotal.totaux.paiement_comptant)).toLocaleString() }}</td>
              <td class="text-right tht-blue"
                  :key="'total2'+gtkey">{{ Math.round(Number(gareTotal.totaux.paiement_carte)).toLocaleString() }}</td>
              <td class="tht-dark-blue text-right"
                  :key="'total3'+gtkey">{{Math.round(Number(monthTotal[gtkey])).toLocaleString() }}</td>
            </template>
            <td class="tht-blue text-right">{{Math.round(Number(monthTotal[gareData.length])).toLocaleString() }}</td>
            
          </tr> -->
        </tbody>
      </table>
    </div>
    <!-- Fin ligne n°4 -->
  </div>
</template>
<style>
.analytique{
  font-size: 0.6em;
}
.bad-news{
  color:#ff0000;
}
.good-news{
  color:#24b13c;
}
</style>
<script>
import Pie from "./PieChart"
import { mapActions, mapMutations, mapGetters } from "vuex"

export default {
  name: "RctPeage",
  components: {
    Pie
  },
  props: ["extYear"],
  data: () => ({
    chartData: {
      labels: [],
      datasets: [
        {
          label: "Recette péage (%)",
          data: [],
          backgroundColor: [],
          hoverOffset: 4,
        },
      ],
    },
    width: "col-md-12",
    year: {
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",  
    },
    color:"",
    yearData: null,
    month: [
      { mois: "Janv.", total: 0 },
      { mois: "Fev.", total: 0 },
      { mois: "Mars", total: 0 },
      { mois: "Avr.", total: 0 },
      { mois: "Mai.", total: 0 },
      { mois: "Juin", total: 0 },
      { mois: "Juil.", total: 0 },
      { mois: "Août.", total: 0 },
      { mois: "Sept.", total: 0 },
      { mois: "Oct.", total: 0 },
      { mois: "Nov.", total: 0 },
      { mois: "Dec.", total: 0 },
    ],
    monthTotal: 0,
    gareData: null,
    gare:"ATTINGUE",
    axe:"",
    listAxe:[
      "Recettes de péage autoroute du nord",
      "Recettes de péage de Thomasset et de Moapé",
      "Recettes de péage autoroute du sud",
      "TOUS"
    ],
    listGare:[],
    total: {
      recette:0,
      direct:0,
      carte:0,
    },
    tableauCouleurs: ["#0097A9", "#016677", "#00B5CB", "#00383F", "#01606B"],
  }),
  created() {
    this.year = this.extYear
    this.RecettePeage(this.year)
    this.RecettePeageG({ annee_debut: this.year.annee_debut,annee_fin: this.year.annee_fin, gare: this.gare })

    if (window.innerWidth > 1400) {
      this.width = "col-md-4 mx-auto"
    }
  },
  watch: { 
    recettePeageG() {
      if (this.recettePeageG["tableau"]) {
        if (this.gare!=null && this.gare!="") {
          if (this.gare !="TOUTES") {
            this.gareData=null
            this.gareData = this.recettePeageG["tableau"][0]
            this.monthTotal = 0
            for (let index = 0; index < this.month.length; index++) {
              this.month[index].total =
                this.gareData.paiement_comptant[index] +
                this.gareData.paiement_carte[index] //+
              // this.gareData.classe_3[index] +
              // this.gareData.classe_4[index] +
              // this.gareData.classe_5[index]
              this.monthTotal += this.month[index].total
            }
            console.log(this.gareData)
          }else{
            this.gareData=null
            this.gareData = this.recettePeageG["tableau"]
            this.monthTotal = []
            this.month= [
              { mois: "Janv.", total:[0]},
              { mois: "Fev.", total:[0]},
              { mois: "Mars", total:[0]},
              { mois: "Avr.", total:[0]},
              { mois: "Mai.", total:[0]},
              { mois: "Juin", total:[0]},
              { mois: "Juil.", total:[0]},
              { mois: "Août.", total:[0]},
              { mois: "Sept.", total:[0]},
              { mois: "Oct.", total:[0]},
              { mois: "Nov.", total:[0]},
              { mois: "Dec.", total:[0]}
            ]
            for (let pointer = 0; pointer < this.gareData.length; pointer++) {
              this.monthTotal.push(0)  
            }
            this.monthTotal.push(0)  

            for (let index = 0; index < this.month.length; index++) {
              for (let pointer = 0; pointer < this.gareData.length; pointer++) {
                this.month[index].total[pointer]=0
              } 
              this.month[index].total.push(0)
            }
            for (let pointer = 0; pointer < this.gareData.length; pointer++) {
              for (let index = 0; index < this.month.length; index++) {
                this.month[index].total[pointer]+=
                  this.gareData[pointer].paiement_comptant[index] +
                  this.gareData[pointer].paiement_carte[index] //+
                // TOTAL de toutes les gares par mois
                this.month[index].total[this.gareData.length]+=
                  this.gareData[pointer].paiement_comptant[index] +
                  this.gareData[pointer].paiement_carte[index]

                this.monthTotal[pointer] += this.month[index].total[pointer]
                //TOTAL DES TOTAUX DE TOUS LES MOIS PAR GARE
                this.monthTotal[this.gareData.length] += this.month[index].total[pointer]
              }
              
            }
            console.log(this.monthTotal)
            console.log(this.month)
          }
        } else {
          if (this.axe!=null && this.axe!="") {
            // if (this.axe !="TOUS") {
            //   this.gareData=null
            //   this.gareData = this.recettePeageG["tableau"][0]
            //   this.monthTotal = 0
            //   for (let index = 0; index < this.month.length; index++) {
            //     this.month[index].total =
            //       this.gareData.paiement_comptant[index] +
            //       this.gareData.paiement_carte[index] //+
            //     // this.gareData.classe_3[index] +
            //     // this.gareData.classe_4[index] +
            //     // this.gareData.classe_5[index]
            //     this.monthTotal += this.month[index].total
            //   }
            //   console.log(this.gareData)
            // }else{
            this.gareData=null
            this.gareData = this.recettePeageG["tableau"]
            this.monthTotal = []
            this.month= [
              { mois: "Janv.", total:[0]},
              { mois: "Fev.", total:[0]},
              { mois: "Mars", total:[0]},
              { mois: "Avr.", total:[0]},
              { mois: "Mai.", total:[0]},
              { mois: "Juin", total:[0]},
              { mois: "Juil.", total:[0]},
              { mois: "Août.", total:[0]},
              { mois: "Sept.", total:[0]},
              { mois: "Oct.", total:[0]},
              { mois: "Nov.", total:[0]},
              { mois: "Dec.", total:[0]}
            ]
            for (let pointer = 0; pointer < this.gareData.length; pointer++) {
              this.monthTotal.push(0)  
            }
            this.monthTotal.push(0)  

            for (let index = 0; index < this.month.length; index++) {
              for (let pointer = 0; pointer < this.gareData.length; pointer++) {
                this.month[index].total[pointer]=0
              } 
              this.month[index].total.push(0)
            }
            for (let pointer = 0; pointer < this.gareData.length; pointer++) {
              for (let index = 0; index < this.month.length; index++) {
                this.month[index].total[pointer]+=
                  this.gareData[pointer].paiement_comptant[index] +
                  this.gareData[pointer].paiement_carte[index] //+
                // TOTAL de toutes les gares par mois
                this.month[index].total[this.gareData.length]+=
                  this.gareData[pointer].paiement_comptant[index] +
                  this.gareData[pointer].paiement_carte[index]

                this.monthTotal[pointer] += this.month[index].total[pointer]
                //TOTAL DES TOTAUX DE TOUS LES MOIS PAR GARE
                this.monthTotal[this.gareData.length] += this.month[index].total[pointer]
              }
            
            }
            console.log(this.monthTotal)
            console.log(this.month)
            // }
          }
        }
      }
    },

    recettePeage() {
      if (this.recettePeage["tableau"]) {
        this.yearData = this.recettePeage
        this.total = {
          recette:0,
          direct:0,
          carte:0,
        }
        this.yearData.tableau.forEach((recette) => {
          this.total.recette += Number(recette.recettes)
          this.total.direct += Number(recette.paiement_direct)
          this.total.carte += Number(recette.paiement_carte)
          this.listGare.push(recette.gare)
        })
        this.listGare.push("CONSOLIDEE")
        this.listGare.push("TOUTES")
        if (this.total.recette - this.yearData.budget_cible > 0) {
          this.color = "good-news"
        } else {
          this.color = "bad-news"
        }
        if (window.innerWidth > 1400) {
          this.width = "col-md-4 mx-auto"
        }
        this.addColor(this.yearData.graphique.length)

        this.yearData.graphique.forEach((graphique) => {
          this.chartData.datasets[0].data.push(
            Math.round(Number(graphique.pourcentage) * 100) / 100
          )
          this.chartData.labels.push(
            graphique.gare +
              " (" +
              Math.round(Number(graphique.pourcentage) * 100) / 100 +
              "%)"
          )
        })
      }
    },
    extYear() {
      if (this.extYear) {
        this.setRecettePeage("")
        
        this.year = this.extYear
        this.yearData = null
        
        this.RecettePeage(this.year)


        this.chartData.datasets[0].data = []
        this.chartData.labels = []
        this.chartData.datasets[0].backgroundColor = []
      }
    },
  },
  computed: {
    ...mapGetters(["arRecetteG", "arRecette", "recettePeage", "recettePeageG"]),
  },
  methods: {
    ...mapActions(["RecettePeage", "RecettePeageG"]),
  
    ...mapMutations(["setRecettePeage", "setRecettePeageG"]),

    changeGare() {
      this.gareData = null
      this.axe=null
      // this.RecettePeage({ annee: this.year })
      this.RecettePeageG({ annee_debut: this.year.annee_debut, annee_fin: this.year.annee_fin, gare: this.gare, axe: this.axe })
    },
    changeAxe() {
      this.gareData = null
      this.gare=null
      // this.RecettePeage({ annee: this.year })
      this.RecettePeageG({ annee_debut: this.year.annee_debut, annee_fin: this.year.annee_fin, gare: this.gare, axe: this.axe })
    },
    addColor(long) {
      for (let i = 0; i < long; i++) {
        this.chartData.datasets[0].backgroundColor.push(
          this.tableauCouleurs[i]
        )
      }
    },
  },
}
</script>

  